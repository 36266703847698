
export default {
  props: {
    content: {
      type: Object,
      default: null,
    },
    isWhite: {
      type: Boolean,
      default: false,
    },
    enableDonation: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showDonationForm: false,
      options: {
        minimizable: false,
      },
    }
  },

  head() {
    return {
      meta: [
        {
          hid: 'og:image',
          property: 'og:image',
          content: this.generatedStencilImageUrl,
        },
      ],
      script: [
        {
          src: 'https://unpkg.com/@lottiefiles/lottie-player@2.0.2/dist/lottie-player.js',
        },
        {
          src: 'https://cdnjs.cloudflare.com/ajax/libs/lottie-web/5.12.2/lottie.min.js',
        },
      ],
    }
  },

  computed: {
    generatedStencilImageUrl() {
      const baseUrl =
        'https://images.usestencil.com/qs/62173ea1-c647-47ea-877e-8ec8ee6fd7a1/FCpa2R74u4ivLNnAwKKbFP.png'

      const url = new URL(baseUrl)
      let params
      if (this.content.hero_title || this.content.title) {
        const queryParams = {
          subtitle: this.$removeHtmlTags(
            this.content.hero_title || this.content.title
          ),
        }
        params = new URLSearchParams(queryParams)
        url.search = params?.toString()
      }

      return url.href
    },
  },

  mounted() {
    this.$nuxt.$on('played', () => {
      this.showDonationForm = true
    })
  },

  methods: {
    scrollDownToContentSection() {
      const contentSection = document.getElementById('content-section')
      const elementRect = contentSection.getBoundingClientRect()
      const absoluteElementTop = elementRect.top + window.pageYOffset
      const middlePosition = absoluteElementTop - window.innerHeight / 4

      window.scrollTo({
        top: middlePosition,
        behavior: 'smooth',
      })
    },

    lbToBr(text) {
      return text.replace(/\n/g, '<br />')
    },
  },
}
