import { render, staticRenderFns } from "./WeihnachtsaktionHero.vue?vue&type=template&id=001392a7&scoped=true&"
import script from "./WeihnachtsaktionHero.vue?vue&type=script&lang=js&"
export * from "./WeihnachtsaktionHero.vue?vue&type=script&lang=js&"
import style0 from "./WeihnachtsaktionHero.vue?vue&type=style&index=0&id=001392a7&prod&scoped=true&lang=scss&"
import style1 from "./WeihnachtsaktionHero.vue?vue&type=style&index=1&id=001392a7&prod&lang=css&"
import style2 from "./WeihnachtsaktionHero.vue?vue&type=style&index=2&id=001392a7&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "001392a7",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LottieSnowflakesAnimation: require('/vercel/path0/components/LottieSnowflakesAnimation.vue').default,LottieCandleAnimation: require('/vercel/path0/components/LottieCandleAnimation.vue').default,ArrowLink: require('/vercel/path0/components/ui/ArrowLink.vue').default,AppLink: require('/vercel/path0/components/ui/AppLink.vue').default,Container: require('/vercel/path0/components/ui/Container.vue').default,Waves: require('/vercel/path0/components/Waves.vue').default,WeihnachtsaktionDonationForm: require('/vercel/path0/components/WeihnachtsaktionDonationForm.vue').default,TransitionExpand: require('/vercel/path0/components/TransitionExpand.vue').default,BottomWaves: require('/vercel/path0/components/BottomWaves.vue').default})
